<template>
  <div>
    <a-card title="计划排产">
      <a-row :gutter="[12, 8]">
        <a-col :span="24" style="width: 256px">
          <a-range-picker @change="onChangePicker" />
        </a-col>
        <a-col :span="24" style="width: 240px">
          <a-input v-model="searchForm.search" placeholder="生产工单号, 销售单号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 240px">
          <a-input v-model="searchForm.goods_search" allowClear placeholder="物料编号, 名称" @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 160px">
          <a-button @click="schedulingModalVisible = true">批量排产</a-button>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :scroll="{ x: 1800 }"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="tableChange"
        >
          <div slot="number" slot-scope="value, item">
            <a @click="detial(item)">{{ value }}</a>
          </div>
          <div slot="sales_order_number" slot-scope="value, item">
            <a v-if="item.sales_order" @click="jumpSalesOrderDetailPage(item)">{{ value }}</a>
            <span v-else>{{ item.sales_number }}</span>
          </div>

          <div slot="status" slot-scope="value, item">
            <a-badge v-if="warningList.indexOf(item.id) === -1" status="success" text="正常" />
            <a-badge v-else status="error" text="库存不足" />
          </div>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button @click="detial(item)">详情</a-button>
              <a-button type="primary" @click="openRequirement(item)">物料需求</a-button>
              <a-button type="primary" @click="openSchedulingModal(item)">排产</a-button>
              <a-button icon="arrow-up" @click="moveUp(item)"></a-button>
              <a-button icon="arrow-down" @click="moveDown(item)"></a-button>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <form-modal v-model="visible" :form="targetItem" @create="create" />
    <BatchSchedulingModal v-model="schedulingModalVisible" :items="selectedRowItems" @create="list()" />
    <RequirementModal v-model="requirementModalVisible" :productionOrderItem="targetItem" @cancel="list()" />
  </div>
</template>

<script>
import {
  productionOrderList,
  productionOrderWarningList,
  productionOrderMoveUp,
  productionOrderMoveDown,
} from "@/api/production";

export default {
  components: {
    FormModal: () => import("./FormModal.vue"),
    BatchSchedulingModal: () => import("./BatchSchedulingModal.vue"),
    RequirementModal: () => import("@/components/RequirementModal"),
  },
  data() {
    return {
      searchForm: {
        search: "",
        page: 1,
        ordering: undefined,
        status: "in_progress",
        ordering: "sort_value",
        is_scheduling: true,
      },
      pagination: { current: 1, total: 0, pageSize: 10, ordering: "-id" },
      loading: false,
      items: [],
      columns: [
        {
          title: "优先级",
          dataIndex: "index",
          width: 80,
          fixed: "left",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "生产工单号",
          dataIndex: "number",
          fixed: "left",
          scopedSlots: { customRender: "number" },
        },
        // {
        //   title: "销售单号",
        //   dataIndex: "sales_order_number",
        //   scopedSlots: { customRender: "sales_order_number" },
        // },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
        },
        {
          title: "计划数量",
          dataIndex: "total_quantity",
          width: 100,
        },
        {
          title: "完成数量",
          dataIndex: "quantity_produced",
          width: 100,
        },
        {
          title: "计划开始时间",
          dataIndex: "planned_start_time",
          width: 180,
        },
        {
          title: "计划完成时间",
          dataIndex: "planned_end_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          fixed: "right",
          width: 240,
          scopedSlots: { customRender: "action" },
        },
      ],
      visible: false,
      targetItem: {},

      requirementModalVisible: false,
      warningList: [],

      selectedRowKeys: [],
      selectedRowItems: [],
      schedulingModalVisible: false,
    };
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      productionOrderList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;

          productionOrderWarningList({ ids: this.items.map((item) => item.id) }).then((data) => {
            this.warningList = data;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create() {
      this.list();
    },
    detial(item) {
      this.$router.push({ path: "/production/detial", query: { id: item.id } });
    },
    moveUp(item) {
      productionOrderMoveUp({ id: item.id }).then(() => {
        this.$message.success("上移成功");
        this.list();
      });
    },
    moveDown(item) {
      productionOrderMoveDown({ id: item.id }).then(() => {
        this.$message.success("下移成功");
        this.list();
      });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      if (sorter.field) {
        if (sorter.field) {
          this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
        }
      }
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0];
      let endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    openSchedulingModal(item) {
      this.targetItem = { ...item };
      this.visible = true;
    },
    openRequirement(item) {
      this.targetItem = item;
      this.requirementModalVisible = true;
    },
    jumpSalesOrderDetailPage(item) {
      this.$router.push({ path: "/sale/sale_record_detail", query: { id: item.sales_order } });
    },
    getRowClassName(item) {
      return this.warningList.indexOf(item.id) === -1 ? "" : "warning-row";
    },
    onSelectChange(selectedRowKeys, selectedRowItems) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowItems = selectedRowItems;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
